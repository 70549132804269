













































































































import Vue from 'vue'
import { mapMutations } from 'vuex'
import { SelectOption } from '@/models/form'
import TerminalCustomSelect, { FavoriteStorageName } from './TerminalCustomSelect.vue'
import ExchangeService from '@/services/exchange'
import { Exchange, ExchangeMarket, ExchangeRefInstrument } from '@/services/exchange/models'
import { EXCHANGE_MUTATION_TYPES } from '@/store/modules/exchange/types'
import StorageKeys from '@/constants/storage-keys'

export default Vue.extend({
  props: {
    value: {
      type: Object as () => Record<string, boolean>,
      required: true
    }
  },

  components: {
    TerminalCustomSelect
  },

  data(): {
    accountSelected?: SelectOption
    accounts: Exchange[]
    accountFavorite: string[]
    markets: ExchangeMarket[]
    marketSelected?: SelectOption
    marketFavorite: string[]
    pairs: ExchangeRefInstrument[]
    pairSelected?: SelectOption
    productTypes: string[]
    productTypeSelected?: SelectOption
    pairFavorite: string[]
    loading: boolean
    eRefInstruments: ExchangeRefInstrument[]
  } {
    return {
      accounts: [],
      accountSelected: undefined,
      accountFavorite: [],
      markets: [],
      marketSelected: undefined,
      marketFavorite: [],
      pairs: [],
      pairSelected: undefined,
      productTypes: [],
      productTypeSelected: undefined,
      pairFavorite: [],
      loading: false,
      eRefInstruments: []
    }
  },

  computed: {
    accountOptions(): SelectOption[] {
      const accountOptions = this.accounts.map((account) => ({
        label: account.name,
        value: account.name,
        img: `/images/portfolios/${account.name}.svg`
      }))

      if (this.accountFavorite.length) {
        return this.accountFavorite
          .map((favorite) => {
            return accountOptions.find((account) => account.value === favorite) as SelectOption
          })
          .filter((account) => !!account)
          .concat(accountOptions.filter((account) => !this.accountFavorite.includes(account.value)))
      }

      return accountOptions
    },

    marketOptions(): SelectOption[] {
      const marketOptions = this.markets.map((market) => ({
        label: market.quoteCurrency,
        value: market.quoteCurrency,
        img: `/images/${market.quoteCurrency.toUpperCase()}.jpg`
      }))

      if (this.marketFavorite.length) {
        return this.marketFavorite
          .map((favorite) => {
            return marketOptions.find((market) => market.value === favorite) as SelectOption
          })
          .filter((market) => !!market)
          .concat(marketOptions.filter((market) => !this.marketFavorite.includes(market.value)))
      }

      return marketOptions
    },

    pairOptions(): SelectOption[] {
      if (!this.marketSelected) return []

      const pairOptions = this.pairs.map((pair) => ({
        label: `${pair.baseCurrency}/${pair.quoteCurrency}`,
        value: pair.symbol,
        note: pair.symbol,
        img: `/images/${pair.baseCurrency.toUpperCase()}.jpg`
      }))

      if (this.pairFavorite.length) {
        return this.pairFavorite
          .map((favorite) => {
            return pairOptions.find((pair) => pair.value === favorite) as SelectOption
          })
          .filter((pair) => !!pair)
          .concat(pairOptions.filter((pair) => !this.pairFavorite.includes(pair.value)))
      }

      return pairOptions
    },

    productTypeOptions(): SelectOption[] {
      return this.productTypes.map((productType) => ({
        label: productType,
        value: productType
      }))
    },

    visibleComponent(): Record<string, boolean> {
      return this.value
    }
  },

  methods: {
    ...mapMutations('exchange', [
      EXCHANGE_MUTATION_TYPES.SET_REF_INSTRUMENT,
      EXCHANGE_MUTATION_TYPES.SET_CURRENT_EXCHANGE,
      EXCHANGE_MUTATION_TYPES.SET_CURRENT_PAIR,
      EXCHANGE_MUTATION_TYPES.SET_CURRENT_MARKET
    ]),

    async getActiveExchanges() {
      try {
        const { data: response } = await ExchangeService.getActiveExchanges()
        if (response.success && response.data && response.data.length > 0) {
          this.accounts = response.data
          const accountSelected = {
            label: response.data[0].name,
            value: response.data[0].name,
            img: `/images/portfolios/${response.data[0].name}.svg`
          }
          this.accountSelected = accountSelected
        }

        return response.data
      } catch (_) {}
    },

    async getExchangeRefInstrument() {
      try {
        this.loading = true
        const { data: eRefInstrumentRes } = await ExchangeService.getExchangeRefInstrument({
          exchange: String(this.accountSelected?.value)
        })
        this.eRefInstruments = eRefInstrumentRes.data || []
        // store
        ;(this as any).SET_REF_INSTRUMENT(this.eRefInstruments)

        this.getProductTypes()
        this.getMarkets()
        this.getPairs()
      } finally {
        this.loading = false
      }
    },

    getProductTypes() {
      this.productTypes = [...new Set(this.eRefInstruments.map((item) => item.productType))]
      this.productTypeSelected = {
        label: this.productTypes[0] || '',
        value: this.productTypes[0] || ''
      }
    },

    getMarkets() {
      /* markets */
      this.markets = [
        ...new Set(
          this.eRefInstruments
            .filter(
              (item) =>
                item.exchange === this.accountSelected?.value && item.productType === this.productTypeSelected?.value
            )
            .map((item) => item.quoteCurrency)
        )
      ].map((item) => ({
        exchange: this.accountSelected?.value,
        quoteCurrency: item
      })) as ExchangeMarket[]

      this.marketSelected = {
        label: this.markets[0]?.quoteCurrency || '',
        value: this.markets[0]?.quoteCurrency || '',
        img: `/images/${this.markets[0]?.quoteCurrency.toUpperCase()}.jpg`
      }
    },

    getPairs() {
      this.pairs = this.eRefInstruments.filter(
        (item) =>
          item.exchange === this.accountSelected?.value &&
          item.quoteCurrency === this.marketSelected?.value &&
          item.productType === this.productTypeSelected?.value
      )

      this.pairSelected = {
        label:
          this.pairs[0]?.baseCurrency || this.pairs[0]?.quoteCurrency
            ? `${this.pairs[0]?.baseCurrency}/${this.pairs[0]?.quoteCurrency}`
            : '',
        value: this.pairs[0]?.symbol,
        note: this.pairs[0]?.symbol,
        img: `/images/${this.pairs[0]?.baseCurrency.toUpperCase()}.jpg`
      }
      ;(this as any).SET_CURRENT_PAIR(this.pairs[0])
    },

    getFavorite(name: FavoriteStorageName) {
      return JSON.parse(String(localStorage.getItem(`${StorageKeys.favorite}.${name}`))) || []
    },

    toggleFavorite({ name, val }: { name: FavoriteStorageName; val: string }) {
      // get favorite
      const favorite = this.getFavorite(name)
      // toggle favorite
      favorite.includes(val) ? favorite.splice(favorite.indexOf(val), 1) : favorite.unshift(val)
      // save favorite
      localStorage.setItem(`${StorageKeys.favorite}.${name}`, JSON.stringify(favorite))
      // update favorite

      switch (name) {
        case 'exchange':
          this.accountFavorite = favorite
          break
        case 'market':
          this.marketFavorite = favorite
          break
        default:
          this.pairFavorite = favorite
      }
    }
  },

  created() {
    this.getActiveExchanges().then((res) => {
      if (!res) return
      this.getExchangeRefInstrument()
    })

    // get favorite in local storage
    this.accountFavorite = this.getFavorite('exchange')
    this.pairFavorite = this.getFavorite('pair')
    this.marketFavorite = this.getFavorite('market')
  },

  watch: {
    accountSelected: {
      handler(val, oldVal) {
        ;(this as any).SET_CURRENT_EXCHANGE(val?.value)
        if (!oldVal) return
        this.getExchangeRefInstrument()
        this.getMarkets()
      },
      deep: true
    },

    productTypeSelected: {
      handler() {
        this.getMarkets()
      },
      deep: true
    },

    marketSelected: {
      handler(val, oldVal) {
        if (val.value) (this as any).SET_CURRENT_MARKET(val.value)
        if (!oldVal) return
        this.getPairs()
      },
      deep: true
    },

    pairSelected: {
      handler(val, oldVal) {
        if (!oldVal || !val) return
        const currentPair = this.pairs.filter((pair) => pair.symbol === val.value)
        ;(this as any).SET_CURRENT_PAIR(currentPair[0])
      },
      deep: true
    },

    value: {
      handler(val) {
        // localStorage.setItem(StorageKeys.visibleComponent, JSON.stringify(val))
        this.$emit('input', val)
      },
      deep: true,
      immediate: true
    }
  }
})
